<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">

            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <router-link :to="{name:'results'}" class="btn  ml-2">
                  <i class="fas fa-arrow-left"></i>
                  Back
                </router-link>

              </div>
              <!--      -->

              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">

                <button @click="calculateTotal" class="btn btn-success ml-2">
                  <i class="fas fa-calculator"></i>
                  {{ exam.is_total_calculated ? 'Re-Calculate' : 'Calculate' }} Total
                </button>

                <button @click="openDialog('course')" class="btn btn-primary ml-2">
                  <i class="fas fa-file-csv"></i>
                  Import Result
                </button>

                <button @click="openDialog('summary')" class="btn btn-primary ml-2">
                  <i class="fas fa-file-csv"></i>
                  Import Result Summary
                </button>

              </div>
              <div class="col-12">
                <div class="row mb-5" style="background-color:whitesmoke">
                  <div class="col-md-3 col-lg-3 col-sm-12">
                    <b>Program :</b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
                  </div>
                  <div class="col-md-2 col-lg-3 col-sm-12">
                    <b>Semester/Year :</b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
                  </div>
                  <div class="col-md-3 col-lg-3 col-sm-12">
                    <b>Exam :</b> {{ exam ? exam.title : null }} <br>
                  </div>
                  <div class="col-md-3 col-lg-3 col-sm-12">
                    <b>Exam Type:</b> {{ exam ? exam.type.toUpperCase() : null }} <br>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="">
                  <table class="table table-stripe">
                    <thead>
                    <th>Exam Date</th>
                    <th>Course Name</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <!--                    <th>Total Student</th>-->
                    <th>Exam Status</th>
                    <th>Result Status</th>
                    <th>Status</th>
                    <th>Action</th>
                    </thead>
                    <tbody>
                    <tr v-for="(course, index) of courses" :key="index">
                      <td>{{ exam.start_date }}</td>
                      <td>{{ course.title }}</td>
                      <td>{{ course.academic_year_title }}</td>
                      <td>{{ course.program_title }}</td>
                      <!--                      <td>{{ exam.total_student }}</td>-->
                      <td>
                                                     <span class="badge"
                                                           :class="exam.exam_status ? exam.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'"
                                                     >{{
                                                         exam.exam_status ? exam.exam_status.toUpperCase() : 'NOT COMPLETED'
                                                       }}</span>

                        <!--                                              Completed, Upcoming-->
                      </td>
                      <td>
                                                    <span class="badge"
                                                          :class="exam.result_status ? exam.result_status =='published'?'badge-success':'badge-warning' :'badge-warning'"
                                                    >{{
                                                        exam.result_status ? exam.result_status.toUpperCase() : 'PENDING'
                                                      }}</span>

                        <br>
                        <span class="badge mt-2"
                              :class="exam.is_total_calculated ?'badge-primary':'badge-warning'"
                        >{{
                            exam.is_total_calculated ? 'Total Marks Calculated' : "Pending Marks Calculation"
                          }}</span>
                      </td>
                      <td>
                                                    <span class="badge badge-primary"
                                                    >{{
                                                        course.pivot.status ? course.pivot.status.split("_").join(" ").toUpperCase() : 'PENDING'
                                                      }}</span>

                      </td>
                      <td>
                        <div class="kt-widget__toolbar" v-if="checkUser(course.id)">
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown"
                              no-caret
                              right
                              no-flip
                              text="Actions">
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                <!--begin::Svg Icon-->
                                <i class="flaticon-more-1"></i>
                                <!--end::Svg Icon-->
                                <!--              </span>-->
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">

                              <!--                                                            <b-dropdown-text tag="div" class="navi-item">-->
                              <!--                                                                &lt;!&ndash;                                <router-link :to="{name:'results-view-all-courses'}"&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                             class="navi-link">&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                                                    <span class="navi-icon">&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                                                      <i class="fa fa-user-tie"></i>&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                                                    </span>&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                  <span class="navi-text">  View Exam Courses</span>&ndash;&gt;-->
                              <!--                                                                &lt;!&ndash;                                </router-link>&ndash;&gt;-->
                              <!--                                                            </b-dropdown-text>-->
                              <b-dropdown-text tag="div" class="navi-item">
                                <router-link
                                    :to="{name:'results-view-all-courses-students',params:{slug:exam.slug, 'courseId':course.id}}"

                                    class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-marker"></i>
                                                                    </span>
                                  <span class="navi-text"> Subject Exam Marks Entry</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <router-link
                                    :to="{name:'results-view-courses-assessments',params:{slug:exam.slug, 'courseId':course.id}}"

                                    class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-bong"></i>
                                                                    </span>
                                  <span class="navi-text">Subject Assessment Marks Entry</span>
                                </router-link>
                              </b-dropdown-text>


                              <b-dropdown-text tag="div" class="navi-item">
                                <a href="#" @click="downloadImportCsv(exam.id,course.id)" class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-download"></i>
                                                                    </span>
                                  <span class="navi-text"> Download Result Excel Format</span>

                                </a>
                              </b-dropdown-text>

                            </div>
                          </b-dropdown>
                        </div>


                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" v-if="academic_classes.length>0">
                <div class="col-12 text-center">
                  <b-pagination
                      @input="getAcademicYears"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
        v-model="dialog"
        width="900"
        title="Result Import"
    >
      <v-card>
        <v-card-title>
          Import {{ resultSummary ? 'Summary' : 'Course' }} Result
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input outlined dense v-model="excel_file"
                            accept=".xlsx, .xls, .csv"
                            label="File input"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn v-if="!resultSummary" :loading="isLoading"
                 color="blue darken-1"
                 text
                 @click="importResult"
          >
            Import
          </v-btn>

          <v-btn v-if="resultSummary" :loading="isLoading"
                 color="blue darken-1"
                 text
                 @click="importResultSummary"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import {mapGetters} from "vuex";
import {API_URL} from "@/core/config";

const examService = new ExamService();
const academicClassService = new AcademicClassService();

export default {
  name: "academic-class",
  components: {},
  data() {
    return {
      dialog: false,
      excel_file: null,
      resultSummary: false,
      isLoading: false,
      academic_classes: [],
      classIds: [],
      courses: [],
      exam: [],
      academic_id: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.getExam()
    if (this.currentUser.type == 'teacher')
      this.getAssignedTeacherCourses()
  },
  methods: {
    checkUser(courseId) {
      if (this.currentUser.type == 'admin') {
        return true;
      } else if (this.currentUser.type == 'teacher' && this.academic_classes && this.classIds.includes(courseId)) {
        // console.log(courseId)

        return true;
      } else {
        return false;
      }

    },
    back() {
      this.$router.go(-1)
    },
    getAssignedTeacherCourses() {
      this.$bus.emit('toggleLoader',);
      academicClassService.getAssignedTeacherCourses(this.currentUser.id).then(response => {
        this.classIds = response.data.courses_id;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    getExam() {
      this.$bus.emit('toggleLoader');
      examService.getBySlug(this.slug).then(response => {
        this.exam = response.data.exam;
        this.getCourses();
      }).catch((err) => {
        //  console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    }, getCourses() {
      examService.getAssignedCourses(this.exam.id).then(response => {
        this.courses = response.data.courses;
      })
    },
    openDialog(type) {
      if (type == 'summary')
        this.resultSummary = true;
      else
        this.resultSummary = false;


      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resultSummary = false;
    },

    assignCourse(exam) {
      this.$refs["assignCourse"].showModal(exam);
    },

    importResult() {
      // console.log('here')
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      examService.importCourseResult(this.exam.id, fd).then(response => {
        this.isLoading = false
        this.excel_file = null;
        this.$snotify.success("Information imported")
        this.examId = null;
        this.dialog = false
        this.getExam();
      })
    },

    calculateTotal() {
      this.exam.calculate_total = 1;
      examService.update(this.exam.id, this.exam).then(response => {
        this.$snotify.success("Total calculation has been scheduled.");
        this.getExam();
      });
    },
    importResultSummary() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      examService.importSummaryResult(this.exam.id, fd).then(response => {
        this.isLoading = false
        this.excel_file = null;
        this.$snotify.success("Information imported")
        this.examId = null;
        this.dialog = false
        this.getExams();
      })
    },


    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            academicClassService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.getAcademicClasses();
            });
          }
        }
      });
    },

    downloadImportCsv(examId, courseId) {
      let url = "";
      let data = {
        exam_id: examId,
        course_id: courseId
      }
      var queryString = Object.keys(data)
          .map(key => {
            return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(data[key])
            );
          })
          .join("&");
      if (queryString) {
        url = url + "?" + queryString;
      }

      url = API_URL + 'download/import/course/result' + url;

      window.open(
          url,
          "_blank"
      );
    }
  }
};
</script>
